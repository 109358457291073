/**
 * bootstrap
 */
 try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');

	const bootstrap = require('bootstrap');
} catch (e) {}


/*---------------------------------------
 $(function)
---------------------------------------*/


$(function() {

	/**
	 * For Front Page
	 */
	if($('body.wp-admin').length <= 0) {

		//スムーズスクロール
		$('#pageTop a[href^="#"]').click(function() {
			var speed = 400;
			var href = $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;

			$('body, html').animate({scrollTop:position}, speed, 'swing');

			return false;
		});


		$('[name=report-cat]').change(function () {
			var value = $('[name=report-cat]').val();
			switch (value) {
				case '11':
					document.getElementById('grantTech').style.display = "block";
					document.getElementById('grantExchange').style.display = "none";
					break;

				case '18':
					document.getElementById('grantTech').style.display = "none";
					document.getElementById('grantExchange').style.display = "block";
					break;

				default:
					document.getElementById('grantTech').style.display = "none";
					document.getElementById('grantExchange').style.display = "none";
					break;
			}
		});

	}// end if


	/**
	 * For Admin Page
	 */
	/*
	if($('body.wp-admin').length) {

		//current_url
		var current_url = location.href.match(/^http.+\?[^=]+=[^&]+/);

		//search button
		$('input#search-submit').on('click',function(){

			var keyword = $('input#post-search-input').val();
			//move
			location.href = current_url + '&s=' + keyword;

			return false;
		});

		//filter button
		$('input#post-query-submit').on('click',function(){

			var next_url = current_url;

			//yearmonth
			var year_month = $('select#filter-by-date').val();
			next_url = next_url + '&m=' + year_month;

			//keyword
			var keyword_query = '';
			var keyword_match = location.href.match(/&s=([^&]+)/);

			if(keyword_match && keyword_match.length > 0) {
				keyword_query = keyword_match[0];
				next_url = next_url + keyword_query;
			}

			//move
			location.href = next_url;

			return false;
		});

	}
	*/


	/**
	 * Header Notice Hide
	 */
	$(window).on('load', function() {
		// setTimeout(function() {
		// 	$('#headerNotice').addClass('hidden');
		// }, 5000);
	});

	/**
	 * Header Notice Close
	 */
	$('#headerNoticeClose').on('click', function() {
		$('#headerNotice').addClass('d-none');
	});


	//タブのメニュー切り替え
	$('#tabWrap li').click(function () {
		var index = $('#tabWrap li').index(this);
			$('.content').css('display', 'none');
			$('.content').eq(index).fadeIn();
			$('#tabWrap li').removeClass('select');
			$(this).addClass('select');
		});

	//#tab02がついたリンクから遷移した時の処理
	var hash = location.hash;
	if (hash == '#tab02') {
		$('#tab02').trigger("click");
		$('html,body').animate({ scrollTop: 0 }, '1');
	}

		//820px以下になったら絞り込み非表示
		var mediaQuery = matchMedia('(max-width: 820px)');
		handle(mediaQuery);
		mediaQuery.addListener(handle);
		function handle(mq) {
			if (mq.matches) {
				$('.searchWrap').css('display', 'block');
				$('.dlWrap').css('display', 'none');
				$('#tabWrap li:last-child').removeClass('select');
				$('#tabWrap li:first-child').addClass('select');
			}
		}


	});


